const mutations = {

  SET_UNITS(state, payload) {
    state.units = payload;
  },

  SET_FREE_UNITS(state, payload) {
    state.freeUnits = payload;
  },

  SET_CREATE_CUSTOMER_WITH_UNIT_FORM(state, payload) {
    state.openCreateCustomerWithUnitForm = payload;
  },

  SET_CUSTOMERS(state, payload) {
    state.customers = payload;
  },

  SET_TEST_TERMINATION_FORM(state, payload) {
    state.openTestTerminationForm = payload;
  },

};

export default mutations;

import ApiPublic from "@/libs/axios";

// import router from '@app/router';

const actions = {
  updateDate({ commit }, moment) {
    commit("UPDATE_DATE", moment);
  },

  fetchLevelChart({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiPublic.get(
        `charts/${payload.type}?date=${payload.date}&device=${payload.device}`,
        {

        },
      )
        .then(({ data }) => {
          commit("UPDATE_LEVEL_CHART", data.results);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
};

export default actions;

import Vue from "vue";

import axios from "axios";

const SERVER_URL = "https://www.waternet.lk/_negombo/display/server/api";

const ApiPublic = axios.create({
  baseURL: SERVER_URL,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
});

Vue.prototype.$http = ApiPublic;

export default ApiPublic;

const mutations = {

  UPDATE_DATE(state, payload) {
    state.selectedDate = payload;
  },

  UPDATE_LEVEL_CHART(state, payload) {
    state.levelChart = payload;
  },

  DOWNLOAD_PROGRESS(state, payload) {
    state.downloadProgress = payload;
  },

  RECENT_DOWNLOADS(state, payload) {
    state.recentDownloads = payload;
  },

};

export default mutations;

import ApiPublic from "@/libs/axios";

const actions = {

  fetchUnits({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiPublic.get(
        "/units",
        {

        },
      )
        .then(({ data }) => {
          commit("SET_UNITS", data.results);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  fetchFreeUnits({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiPublic.get(
        "/units?freeUnitsOnly=1",
        {

        },
      )
        .then(({ data }) => {
          commit("SET_FREE_UNITS", data.results);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  fetchCustomers({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiPublic.get(
        "/customers",
        {

        },
      )
        .then(({ data }) => {
          commit("SET_CUSTOMERS", data.results);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  createCustomerWithUnit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiPublic.post(
        "/customers/with-unit",
        {
          ...payload,
        },
      )
        .then(({ data }) => {
          commit("SET_UNITS", data.results);
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  testTermination({ commit }, payload) {
    return new Promise((resolve, reject) => {
      ApiPublic.put(
        `/customers/${payload.id}/test-termination`,
        {

        },
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },

  openCreateCustomerWithUnitForm({ commit }, payload) {
    commit("SET_CREATE_CUSTOMER_WITH_UNIT_FORM", payload);
  },

  openTestTerminationForm({ commit }, payload) {
    commit("SET_TEST_TERMINATION_FORM", payload);
  },

};

export default actions;

import moment from "moment-timezone";

const state = {
  selectedDate: moment().tz("Asia/Colombo"),

  levelChart: null,

};

export default state;

import moment from "moment-timezone";

const state = {
  units: [],
  customers: [],
  freeUnits: [],
  openCreateCustomerWithUnitForm: false,
  openTestTerminationForm: false,
};

export default state;
